<template>
  <div class="userinfo-orderList">
    <div class="title pointer flex-center-between">
      <div @click="back">
        <i class="el-icon-arrow-left"></i>
        {{ $t('shou-jian-di-zhi') }}
      </div>
      <div>
        <el-button @click="showDialog = true">
          <i class="el-icon-plus"></i>
          {{ $t('xin-zeng-di-zhi') }}
        </el-button>
      </div>
    </div>

    <div class="address-list flex-wrap">
      <div class="address" v-for="(item, i) in addressList" :key="i">
        <div class="flex-center-between info">
          <div>{{ item.FullName }} {{ item.Phone }}</div>
          <div>
            <el-dropdown @command="(e) => addressAction(e, item)">
              <i class="el-icon-more"></i>
              <el-dropdown-menu slot="dropdown">
                <!-- <el-dropdown-item command="1">编辑</el-dropdown-item> -->
                <el-dropdown-item command="2">{{
                  $t('she-wei-mo-ren-di-zhi')
                }}</el-dropdown-item>
                <el-dropdown-item command="3">{{
                  $t('shan-chu')
                }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="gray">
          {{ item.Country }} {{ item.Province }} {{ item.City }}
          {{ item.Address }}
        </div>
      </div>
    </div>
    <addressDialog
      :showDialog="showDialog"
      @close="showDialog = false"
      @init="initAddress"
    ></addressDialog>
  </div>
</template>
<script>
import {
  shippingAddressList,
  setDefaultAddress,
  deleteAddress
} from '@/api/user'
import addressDialog from './address.vue'
export default {
  components: {
    addressDialog
  },
  data() {
    return {
      form: {},
      showDialog: false,
      addressList: []
    }
  },
  mounted() {
    this.initAddress()
  },
  methods: {
    initAddress() {
      shippingAddressList({
        Current: 1,
        PageSize: 100
      }).then((res) => {
        this.addressList = res.data.Items.map((v) => {
          return {
            ...v,
            isDefault: v.IsSelected == 1
          }
        })
      })
    },
    changeTab(i) {
      this.tabIndex = i
    },
    currentChange(page) {},
    back() {
      this.$router.go(-1)
    },
    addressAction(e, data) {
      if (e == 1) {
        //编辑
        this.showDialog = true
      } else if (e == 2) {
        //设为默认
        setDefaultAddress({
          ID: data.ID
        }).then((res) => {
          if (res.code == 0) {
            this.initAddress()
          } else {
            this.$message.warning(res.msg)
          }
        })
      } else {
        //删除
        this.$confirm(this.$t('que-ren-shan-chu'), this.$t('ti-shi'), {}).then(
          () => {
            deleteAddress({
              ID: data.ID
            }).then((res) => {
              if (res.code == 0) {
                this.initAddress()
              } else {
                this.$message.warning(res.msg)
              }
            })
          }
        )
      }
    }
  }
}
</script>